import * as React from "react";
import {useRef} from "react";
import {Helmet} from "react-helmet";
import {Container} from "reactstrap";
import RealEstateOptionsExchange from "../../components/portfolio/RealEstateOptionsExchange/RealEstateOptionsExchange";
import BeYourOwnPortfolioManager from "../../components/portfolio/BeYourOwnPortfolioManager/BeYourOwnPortfolioManager";
import Crashlister from "../../components/portfolio/Crashlister/Crashlister";
import PortfolioCarousel from "../../components/PortfolioCarousel/PortfolioCarousel";

/**
 * Scroll to ref
 *
 * @param ref
 */
// function scrollToRef(ref: any): () => void {
//     return (): void => {
//         window.scrollTo({left: 0, top: ref.current.offsetTop, behavior: "smooth"})
//     }
// }

function openPage(url: string): () => void {
    return (): void => {
    window.location.replace(url);
    }
}

const PortfolioPage: React.FunctionComponent = (): JSX.Element => {
    const realEstateRef = useRef(null);
    const beYourOwnPortfolioManagerRef = useRef(null);
    const crashlisterRef = useRef(null);

    return (
        <React.Fragment>
            <Helmet>
                <title>Portfolio | Real Estate Options Exchange, Be Your Own Portfolio Manager, Crashlister</title>
                <meta name="Description" content="View the portfolio of Frame One Software with great applications such as Is The Massey Tunnel Okay and Koobook."/>
            </Helmet>
            <div className="mb-5">

                <PortfolioCarousel
                    items={[
                        {
                            wideSource: process.env.PUBLIC_URL + "/images/portfolio/RealEstateOptionsExchange/real-estate-carousel.jpg",
                            altText: "Real Estate Options Exchange",
                            captionHeader: "Real Estate Options Exchange",
                            captionText: "Buy and sell real estate derivatives",
                            buttonClick: openPage("/portfolio/real-estate-options"),
                        },
                        {
                            wideSource: process.env.PUBLIC_URL + "/images/portfolio/BeYourOwnPortfolioManager/be-your-own-portfolio-manager-carousel.jpg",
                            altText: "Be Your Own Portfolio Manager",
                            captionHeader: "Be Your Own Portfolio Manager",
                            captionText: "Portfolio optimization software platform",
                            buttonClick: openPage("/portfolio/be-your-own-portfolio-manager"),
                        },
                        // {
                        //     wideSource: process.env.PUBLIC_URL + "/images/portfolio/Crashlister/crashlister-carousel.jpg",
                        //     altText: "Crashlister",
                        //     captionHeader: "Crashlister",
                        //     captionText: "Real estate valuation",
                        //     buttonClick: scrollToRef(crashlisterRef),
                        // }
                    ]}
                />

                <Container className="my-5">
                    {/* <div ref={realEstateRef} className="my-5">
                        <RealEstateOptionsExchange/>
                    </div>

                    <hr/>

                    <div ref={beYourOwnPortfolioManagerRef} className="my-5">
                        <BeYourOwnPortfolioManager/>
                    </div>

                    <hr/>

                    <div ref={crashlisterRef} className="my-5">
                        <Crashlister/>
                    </div> */}

                    <hr/>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default PortfolioPage;