import React from 'react';
import {BrowserRouter, Route, Switch, useLocation} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import ScrollMemory from "react-router-scroll-memory";
import Header from "./components/Header/Header";
import HomePage from "./pages/HomePage/HomePage";
import PeoplePage from "./pages/PeoplePage/PeoplePage";
import PortfolioPage from "./pages/PortfolioPage/PortfolioPage";
import RealEstateOptionsExchangePage
    from "./pages/portfolio/RealEstateOptionsExchange/RealEstateOptionsExchangePage";
import BeYourOwnPortfolioManagerPage from "./pages/portfolio/BeYourOwnPortfolioManager/BeYourOwnPortfolioManagerPage";
// import CrashlisterPage from "./pages/portfolio/Crashlister/CrashlisterPage";
import Footer from "./components/Footer/Footer";

interface IAppProps {

}

const App: React.FC<IAppProps> = (props: IAppProps) => {
    let location = useLocation();
    return (
        <React.Fragment>
            <ScrollMemory/>
            <ScrollToTop/>
            {/*{location.pathname !== "/" && <Header/>}*/}
            <Header/>
            <main>
                <Switch>

                    <Route path="/" exact component={HomePage}/>
                    <Route path="/people" exact component={PeoplePage}/>
                    <Route path="/portfolio" exact component={PortfolioPage}/>
                    <Route path="/portfolio/real-estate-options" exact component={RealEstateOptionsExchangePage}/>
                    <Route path="/portfolio/be-your-own-portfolio-manager" exact component={BeYourOwnPortfolioManagerPage}/>
                    {/* <Route path="/portfolio/crashlister" exact component={CrashlisterPage}/> */}

                </Switch>
            </main>

            {location.pathname !== "/" && <Footer/>}
        </React.Fragment>
    );
};

export default App;
