import * as React from "react";
import {Col, Container, Row} from "reactstrap";
import "./Footer.scss";
import {Link} from "react-router-dom";

interface IFooterProps {

}

const Footer: React.FunctionComponent<IFooterProps> = (props: IFooterProps): JSX.Element => {
    return (
        <footer className="py-4" style={{backgroundColor: "#f8f9fa"}}>
            <Container>
                <Row>
                    <Col sm={12} lg={3}
                         className="d-flex align-items-center justify-content-center flex-column  mb-4 mb-lg-0">
                        <img
                            src={process.env.PUBLIC_URL + "/images/logos/jazmode-software.png"}
                            alt="Jazmode Software"
                            style={{maxHeight: 60, maxWidth: "100%", objectFit: "contain"}}
                        />
                    </Col>

                    <Col sm={12} lg={{size: 3, offset: 6}} className="mb-4 mb-lg-0">
                        <h6 className="text-center text-lg-left">
                            Momo Deretic
                        </h6>

                        <hr className="footer-contact-hr"/>

                        <div className="mb-2">
                            <a
                                href="mailto:info@jazmodesoftware.com?subject=Software Consultation"
                                target="_blank"
                                className="footer-contact-link"
                            >
                                <p className="text-center text-lg-left mb-0 contact-link-padding-helper text-primary">
                                    info@jazmodesoftware.com
                                </p>
                            </a>
                        </div>

                        <div>
                            <a href="tel:6043587349" className="footer-contact-link">
                                <p className="text-center text-lg-left mb-0 contact-link-padding-helper text-primary">
                                    (604) 358-7349
                                </p>
                            </a>
                        </div>
                    </Col>

                    {/*<Col sm={12} lg={3} className="mb-4 mb-lg-0">*/}
                    {/*    <h6 className="text-center text-lg-left">*/}
                    {/*        Jasmina Arifovic*/}
                    {/*    </h6>*/}

                    {/*    <hr className="footer-contact-hr"/>*/}

                    {/*    <div className="mb-2">*/}
                    {/*        <a*/}
                    {/*            href="mailto:jasmina@jazmodesoftware.com?subject=Software Consultation"*/}
                    {/*            target="_blank"*/}
                    {/*            className="footer-contact-link"*/}
                    {/*        >*/}
                    {/*            <p className="text-center text-lg-left mb-0 contact-link-padding-helper text-primary">*/}
                    {/*                jasmina@jazmodesoftware.com*/}
                    {/*            </p>*/}
                    {/*        </a>*/}
                    {/*    </div>*/}

                    {/*    <div>*/}
                    {/*        <a href="tel:6043587349" className="footer-contact-link">*/}
                    {/*            <p className="text-center text-lg-left mb-0 contact-link-padding-helper text-primary">*/}
                    {/*                (604) 358-7349*/}
                    {/*            </p>*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
