import * as React from "react";
import {Helmet} from "react-helmet";
import ScalableBackground, {ISectionProperty} from "../../components/scalable_background/ScalableBackground";
import RealEstateOptionsPreview
    from "../../components/HomePageContent/RealEstateOptionsPreview/RealEstateOptionsPreview";
import LazyHero from 'react-lazy-hero';
import LogoShowcase from "../../components/HomePageContent/LogoShowcase/LogoShowcase";
import Fade from "react-reveal/Fade"
import ThePeople from "../../components/HomePageContent/ThePeople/ThePeople";
import {StickyContainer, Sticky} from "react-sticky";
import Header from "../../components/Header/Header";
import {Col, Row} from "react-bootstrap";
import {Container} from "reactstrap";
import "./HomePage.scss";


interface IHomePageProps {

}

const sectionListProperties: ISectionProperty[] = [
    {color: "#FDFDFD"},
    {color: "#F9F8F9"},
    // {color: "rgba(203, 219, 213, 1)"},
];

const HomePage: React.FunctionComponent<IHomePageProps> = (props: IHomePageProps): JSX.Element => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Home | Jazmode Software</title>
                <meta name="Description" content="The founders of Jazmode Software Associates"/>
            </Helmet>

            <LazyHero
                imageSrc={process.env.PUBLIC_URL + "/images/bowen_island.jpg"}
                parallaxOffset={100}
                transitionDuration={0}
                color="#5d777bcc"
                minHeight="100vh"
            >
                <Fade>
                    <Container className="my-5">
                        <Row className="d-flex justify-content-center hero-image-logo-spacer my-5">
                            <Col md={6} sm={8} xs={10}>
                                <img src={process.env.PUBLIC_URL + "/images/logos/jazmode-software-white.png"}
                                     alt="jazmode-software-logo" style={{maxHeight: 240}}/>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center hero-image-logo-spacer my-5 px-2">
                            <Col md={6} sm={8} xs={10}>
                                <p className="text-justify font-secondary-color">
                                    Jazmode Software grew out of Majic Software, a partnership started on Bowen Island, British Columbia in 1993 between Momo Deretic,
                                    lecturer at Sauder School of Business, and Jasmina Arifovic, who was professor of economics at Simon Fraser university.
                                    Over the years, we have worked on the development of trading software platforms for retail users, 
                                    primarily stock portfolio optimization and real estate derivatives.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Fade>
            </LazyHero>

            {/*	<StickyContainer>*/}

            {/*		<Sticky>*/}
            {/*			{(props) => (*/}
            {/*				<div style={{...props.style, zIndex: 99999}}>*/}
            {/*					<Header/>*/}
            {/*				</div>*/}
            {/*			)}*/}
            {/*		</Sticky>*/}

            {/*		<ScalableBackground sectionProperties={sectionListProperties}>*/}
            {/*			<RealEstateOptionsPreview/>*/}

            {/*			<LogoShowcase/>*/}

            {/*			<ThePeople/>*/}
            {/*		</ScalableBackground>*/}
            {/*	</StickyContainer>*/}
        </React.Fragment>
    )
};

export default HomePage;
