import * as React from "react";
import LazyHero from "react-lazy-hero";
import Helmet from "react-helmet";
import { Container} from "reactstrap";

const RealEstateOptionsExchangePage: React.FunctionComponent = (): JSX.Element => {

    return (
        <React.Fragment>
            <Helmet>
                <title>Real Estate Options Exchange</title>
                <meta name="Description" content="Real Estate Options Exchange"/>
            </Helmet>

            <LazyHero
                imageSrc={process.env.PUBLIC_URL + "/images/portfolio/RealEstateOptionsExchange/real-estate-bg.jpg"}
                transitionDuration={0}
                minHeight="65vh"
                color={"#383838"}
            >
                <div className="d-flex justify-content-center align-items-center">
                    <img
                        src={process.env.PUBLIC_URL + "/images/logos/logo-blue.png"}
                        alt="Real Estate Options Exchange Logo"
                        style={{height: "200px"}}
                    />
                </div>
            </LazyHero>

            <Container className="mt-3 mb-5">
                <h1>Real Estate Options Exchange</h1>
                <p className="text-justify text-sm-left">
                    Real Estate Options Exchange provides the platform where private individuals can buy and sell real estate derivatives (options) to hedge against adverse changes in the future value of residential real estate assets, such as apartments and houses.
                </p>
                <ul>
                            <li>Real time trading with other traders.</li>
                            <li>Options from all major regions of the Greater Vancouver Area.</li>
                            <li>Hedges both against the increase and the decrease in future value of real estate assets.</li>
                        </ul>
            </Container>
        </React.Fragment>
    );
}

export default RealEstateOptionsExchangePage;