import * as React from "react";
import {ReactNode, useState} from "react";
import {Carousel, CarouselControl, CarouselIndicators, CarouselItem} from "reactstrap";
import "./PortfolioCarousel.scss";

export interface ICarouselItem {
    wideSource: string;
    mobileSource?: string;
    altText: string;
    captionHeader: string;
    captionText: string;
    buttonText?: string;

    buttonClick?(): void;
}

export interface IPortfolioCarouselProps {
    items: ICarouselItem[];
}

const PortfolioCarousel: React.FunctionComponent<IPortfolioCarouselProps> = (props: IPortfolioCarouselProps): JSX.Element => {

    const {items} = props;

    const [activeIndex, updateActiveIndex] = useState(0);
    const [animating, updateAnimating] = useState(false);

    /**
     * Set animating = true
     *
     */
    function onExiting(): void {
        updateAnimating(true);
    }

    /**
     * Set animating = false
     *
     */
    function onExited(): void {
        updateAnimating(false);
    }

    /**
     * Move right
     *
     */
    function next(): void {
        if (animating) {
            return;
        }

        const nextIndex: number = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        updateActiveIndex(nextIndex);
    }

    /**
     * Move left
     *
     */
    function previous(): void {
        if (animating) {
            return;
        }

        const nextIndex: number = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        updateActiveIndex(nextIndex);

    }

    /**
     * Move to x
     *
     * @param nextIndex
     */
    function goToIndex(nextIndex: number): void {
        if (animating) {
            return;
        }

        updateActiveIndex(nextIndex);
    }

    /**
     * Make ReactNodes for each of the items
     *
     * @param items
     */
    function makeSlides(items: ICarouselItem[]): ReactNode {
        return items.map((item: ICarouselItem, i: number) => {
            return (
                <CarouselItem
                    onExiting={onExiting}
                    onExited={onExited}
                    key={`carousel-item-${i}`}
                >
                    <div className="carousel-image-bg-helper"/>

                    <div className="carousel-content-control">
                        <div className="carousel-content-inner">

                            <div
                                className="carousel-cta-button"
                                onClick={item.buttonClick}
                            >
                                {item.buttonText ? item.buttonText : "Read More"}
                            </div>
                        </div>
                    </div>

                    <img
                        src={item.wideSource}
                        alt={item.altText}
                        className={"carousel-image" + (item.mobileSource ? " carousel-image-full" : "")}
                    />

                    {item.mobileSource && (
                        <img
                            src={item.mobileSource}
                            alt={item.altText}
                            className={"carousel-image carousel-image-mobile"}
                        />
                    )}

                    <div className="carousel-caption">
                        <h3>{item.captionHeader}</h3>
                        <p>{item.captionText}</p>
                    </div>
                </CarouselItem>
            );
        });
    }

    return (
        <React.Fragment>

            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                keyboard={true}
                interval={5000}
                autoPlay={true}
                pause={false}
                ride="carousel"
            >
                <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                />

                {makeSlides(items)}


                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous}/>
                <CarouselControl direction="next" directionText="Next" onClickHandler={next}/>
            </Carousel>
        </React.Fragment>
    );
};

export default PortfolioCarousel;
