import * as React from "react";
import LazyHero from "react-lazy-hero";
import Helmet from "react-helmet";
import { Container} from "reactstrap";

const BeYourOwnPortfolioManagerPage: React.FunctionComponent = (): JSX.Element => {

    return (
        <React.Fragment>
            <Helmet>
                <title>Be Your Own Portfolio Manager</title>
                <meta name="Description" content="Be Your Own Portfolio Manager"/>
            </Helmet>

            <LazyHero
                imageSrc={process.env.PUBLIC_URL + "/images/portfolio/BeYourOwnPortfolioManager/be-your-own-portfolio-manager-bg.jpg"}
                transitionDuration={0}
                minHeight="65vh"
                color={"#383838"}
            >
                <div className="d-flex justify-content-center align-items-center">
                    <img
                        src={process.env.PUBLIC_URL + "/images/logos/be-your-own-portfolio-manager.png"}
                        alt="Be Your Own Portfolio Manager Logo"
                        style={{height: "200px"}}
                    />
                </div>
            </LazyHero>

            <Container className="mt-3 mb-5">
                <h1>Be Your Own Portfolio Manager</h1>
                <p className="text-justify text-sm-left">
                    Developed in 1995, "Be Your Own Portfolio Manager 1.0" was a portfolio optimization software platform
                    aimed primarily at private individuals with stock holdings.
                    It relied on the analysis of historical stock returns to make recommendations for stock
                    picks based on user preferences and then employing optimization techniques to minimize the
                    variance of the selected portfolio for a given return.
                    <br/><br/>
                    "Be your own portfolio manager 2.0" was developed in 2004 and support was discontinued after 2007.
                    The major market for the platform were students at colleges and universities in BC who
                    used the platform in their portfolio management classes.
                </p>
            </Container>
        </React.Fragment>
    );
}

export default BeYourOwnPortfolioManagerPage;