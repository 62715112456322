import * as React from "react";
import "./Header.scss";
import {NavLink} from "react-router-dom";
import {
    Container,
    Nav,
    Navbar,
    NavItem,
} from "reactstrap";

interface IHeaderProps {

}

const Header: React.FunctionComponent<IHeaderProps> = (props: IHeaderProps): JSX.Element => {
    return (
        <div>
            <Navbar color="light" light expand="md">
                <Container>

                    <Nav navbar>
                        <NavItem>
                            <NavLink exact to="/" className="text-decoration-none nav-link navbar-brand">
                                <img
                                    src={process.env.PUBLIC_URL + "/images/logos/jazmode-software.png"}
                                    alt="jazmode-software-logo"
                                    className="header-image object-fit-contain"
                                />
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <Nav className="ml-auto d-flex align-items-center" navbar>
                        <NavItem>
                            <NavLink exact to="/" className="text-decoration-none nav-link">
                                Home
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink exact to="/people" className="text-decoration-none nav-link">
                                People
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink to="/portfolio" className="text-decoration-none nav-link">
                                Portfolio
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Container>
            </Navbar>
        </div>
    );
};

export default Header;
